var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.ok
                  ? _c(
                      "div",
                      [
                        _c("validation-observer", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ invalid, handleSubmit }) {
                                  return [
                                    _c(
                                      "b-form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            staticClass: "mt-1",
                                            attrs: {
                                              header: "Dati Identificativi",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-md-3",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "b-button-toolbar",
                                                              [
                                                                _c(
                                                                  "b-button-group",
                                                                  [
                                                                    _c(
                                                                      "base-input",
                                                                      {
                                                                        attrs: {
                                                                          name: _vm
                                                                            .beForm[
                                                                            _vm
                                                                              .rep
                                                                          ]
                                                                            .insurance_policy_id
                                                                            .label,
                                                                          label:
                                                                            _vm
                                                                              .beForm[
                                                                              _vm
                                                                                .rep
                                                                            ]
                                                                              .insurance_policy_id
                                                                              .label,
                                                                          rules:
                                                                            _vm.getRules(
                                                                              "insurance_policy_id"
                                                                            ),
                                                                          readonly: true,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form
                                                                              .inpt_label,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.form,
                                                                                "inpt_label",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form.inpt_label",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "mt-4 btn-quick",
                                                                        attrs: {
                                                                          size: "sm",
                                                                          text: "Button",
                                                                          variant:
                                                                            "lisaweb",
                                                                          title:
                                                                            "Ricerca Veloce",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.openQuickSearchPolicy,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "search",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "insurance_policy_id col-md-4",
                                                      },
                                                      [
                                                        _vm.insurance_policy_data
                                                          ? _c("span", {
                                                              staticClass:
                                                                "info",
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.toInfoData(
                                                                      _vm.insurance_policy_data,
                                                                      "insurance_policy_ext1",
                                                                      0
                                                                    )
                                                                  ),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "insurance_policy_id col-md-4",
                                                      },
                                                      [
                                                        _vm.insurance_policy_data
                                                          ? _c("span", {
                                                              staticClass:
                                                                "info",
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.toInfoData(
                                                                      _vm.insurance_policy_data,
                                                                      "insurance_policy_ext2",
                                                                      0
                                                                    )
                                                                  ),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "quick-search-policy-modal",
                                                      {
                                                        ref: "quickSearchPolicy",
                                                        on: {
                                                          input:
                                                            _vm.handlePolicyModalInput,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].number.label,
                                                          vid: "number",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .number.label,
                                                          placeholder:
                                                            "Inserisci un numero sinistro",
                                                          rules:
                                                            _vm.getRules(
                                                              "number"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .number,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "number",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].number",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].claim_type.label,
                                                          vid: "claim_type",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .claim_type.label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .claim_type
                                                              .options,
                                                          rules:
                                                            _vm.getRules(
                                                              "claim_type"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .claim_type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "claim_type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].claim_type",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].status_claim.label,
                                                          vid: "status_claim",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .status_claim
                                                              .label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .status_claim
                                                              .options,
                                                          rules:
                                                            _vm.getRules(
                                                              "status_claim"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .status_claim,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "status_claim",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].status_claim",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].insurer_risk_branch
                                                            .label,
                                                          vid: "insurer_risk_branch",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .insurer_risk_branch
                                                              .label,
                                                          placeholder:
                                                            "Inserisci un ramo sinistro",
                                                          rules: _vm.getRules(
                                                            "insurer_risk_branch"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .insurer_risk_branch,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "insurer_risk_branch",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].insurer_risk_branch",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "referring_at",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].referring_at.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .referring_at
                                                              .label,
                                                          type: "number",
                                                          rules:
                                                            _vm.getRules(
                                                              "referring_at"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .referring_at,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "referring_at",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].referring_at",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("correspondence-form", {
                                          attrs: {
                                            beECForm: _vm.beForm[_vm.cor],
                                            repository: _vm.cor,
                                          },
                                          model: {
                                            value: _vm.correspondence_form,
                                            callback: function ($$v) {
                                              _vm.correspondence_form = $$v
                                            },
                                            expression: "correspondence_form",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-card",
                                          {
                                            staticClass: "mt-1",
                                            attrs: {
                                              header: "Altre Informazioni",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].dcpd_module_type
                                                            .label,
                                                          vid: "dcpd_module_type",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .dcpd_module_type
                                                              .label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .dcpd_module_type
                                                              .options,
                                                          rules:
                                                            _vm.getRules(
                                                              "dcpd_module_type"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .dcpd_module_type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "dcpd_module_type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].dcpd_module_type",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-radio", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].dcpd_module_filled
                                                            .label,
                                                          vid: "dcpd_module_filled",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .dcpd_module_filled
                                                              .label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .dcpd_module_filled
                                                              .options,
                                                          rules:
                                                            _vm.getRules(
                                                              "dcpd_module_filled"
                                                            ),
                                                          stacked: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .dcpd_module_filled,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "dcpd_module_filled",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].dcpd_module_filled",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].is_covered.label,
                                                          vid: "is_covered",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .is_covered.label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .is_covered
                                                              .options,
                                                          rules:
                                                            _vm.getRules(
                                                              "is_covered"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .is_covered,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "is_covered",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].is_covered",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].deductible.label,
                                                          vid: "deductible",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .deductible.label,
                                                          rules:
                                                            _vm.getRules(
                                                              "deductible"
                                                            ),
                                                          placeholder:
                                                            "Inserisci franchigia",
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .deductible,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "deductible",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].deductible",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].amount_in_reserve
                                                            .label,
                                                          vid: "amount_in_reserve",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .amount_in_reserve
                                                              .label,
                                                          rules:
                                                            _vm.getRules(
                                                              "amount_in_reserve"
                                                            ),
                                                          placeholder:
                                                            "Inserisci ammontare in riserva",
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .amount_in_reserve,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "amount_in_reserve",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].amount_in_reserve",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].settlement_expense
                                                            .label,
                                                          vid: "settlement_expense",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .settlement_expense
                                                              .label,
                                                          rules:
                                                            _vm.getRules(
                                                              "settlement_expense"
                                                            ),
                                                          placeholder:
                                                            "Inserisci spese di liquidazione",
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .settlement_expense,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "settlement_expense",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].settlement_expense",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].settled_total.label,
                                                          vid: "settled_total",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .settled_total
                                                              .label,
                                                          placeholder:
                                                            "Inserisci totale liquidato",
                                                          rules:
                                                            _vm.getRules(
                                                              "settled_total"
                                                            ),
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .settled_total,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "settled_total",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].settled_total",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "title",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].title.label,
                                                          label:
                                                            "Descrizione Accadimento",
                                                          rules:
                                                            _vm.getRules(
                                                              "title"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .title,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "title",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].title",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "occurred_at",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].occurred_at.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .occurred_at
                                                              .label,
                                                          rules:
                                                            _vm.getRules(
                                                              "occurred_at"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .occurred_at,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "occurred_at",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].occurred_at",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "partially_settled_at",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].partially_settled_at
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .partially_settled_at
                                                              .label,
                                                          rules: _vm.getRules(
                                                            "partially_settled_at"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .partially_settled_at,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "partially_settled_at",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].partially_settled_at",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].place.label,
                                                          vid: "place",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .place.label,
                                                          placeholder:
                                                            "Inserisci un luogo",
                                                          rules:
                                                            _vm.getRules(
                                                              "place"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .place,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "place",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].place",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.customInputs[_vm.rep]
                                                    .length,
                                                expression:
                                                  "customInputs[rep].length",
                                              },
                                            ],
                                            staticClass: "mt-2",
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                attrs: {
                                                  header:
                                                    "Attributi personalizzati",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c(
                                                      "b-row",
                                                      _vm._l(
                                                        _vm.customInputs[
                                                          _vm.rep
                                                        ],
                                                        function (
                                                          element,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "custom-inputs",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                input: element,
                                                                beForm:
                                                                  _vm.beForm[
                                                                    _vm.rep
                                                                  ],
                                                                beRules:
                                                                  _vm.beRules[
                                                                    _vm.rep
                                                                  ],
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form[
                                                                    _vm.rep
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      _vm.rep,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form[rep]",
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "py-2 mb-4" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  disabled: invalid,
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return handleSubmit(
                                                      _vm.onSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Salva\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.resetForm()
                                                  },
                                                },
                                              },
                                              [_vm._v("Reset")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$router.back()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-icon-chevron-double-left",
                                                  {
                                                    attrs: {
                                                      "font-scale": "0.9",
                                                    },
                                                  }
                                                ),
                                                _vm._v("Torna indietro"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2561718922
                          ),
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }